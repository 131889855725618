<template>
    <div
        class="
            relative
            pt-12
            pb-16
            px-4
            sm:pt-16 sm:px-6
            lg:px-8 lg:max-w-7xl lg:mx-auto
        "
    >
        <div class="lg:pr-8">
            <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <form
                    class="
                        mt-9
                        grid grid-cols-1
                        gap-y-6
                        sm:grid-cols-2 sm:gap-x-8
                    "
                >
                    <div>
                        <label
                            for="first_name"
                            class="block text-sm font-medium text-gray-700"
                            >Prénom</label
                        >
                        <div class="mt-1">
                            <input
                                v-model="formData.firstName"
                                type="text"
                                name="first_name"
                                id="first_name"
                                autocomplete="given-name"
                                class="
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    focus:ring-green-500 focus:border-green-500
                                    border-gray-300
                                    rounded-md
                                "
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            for="last_name"
                            class="block text-sm font-medium text-gray-700"
                            >Nom de famille</label
                        >
                        <div class="mt-1">
                            <input
                                v-model="formData.lastName"
                                type="text"
                                name="last_name"
                                id="last_name"
                                autocomplete="family-name"
                                class="
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    focus:ring-green-500 focus:border-green-500
                                    border-gray-300
                                    rounded-md
                                "
                                required
                            />
                        </div>
                    </div>
                    <div class="sm:col-span-2">
                        <label
                            for="email"
                            class="block text-sm font-medium text-gray-700"
                            >Courriel</label
                        >
                        <div class="mt-1">
                            <input
                                v-model="formData.email"
                                id="email"
                                name="email"
                                type="email"
                                autocomplete="email"
                                class="
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    focus:ring-green-500 focus:border-green-500
                                    border-gray-300
                                    rounded-md
                                "
                                required
                            />
                        </div>
                    </div>
                    <div class="sm:col-span-2">
                        <div class="flex justify-between">
                            <label
                                for="phone"
                                class="block text-sm font-medium text-gray-700"
                                >Téléphone</label
                            >
                            <span
                                id="phone_description"
                                class="text-sm text-gray-500"
                                >Optionnel</span
                            >
                        </div>
                        <div class="mt-1">
                            <input
                                v-model="formData.phoneNumber"
                                type="text"
                                name="phone"
                                id="phone"
                                autocomplete="tel"
                                aria-describedby="phone_description"
                                class="
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    focus:ring-green-500 focus:border-green-500
                                    border-gray-300
                                    rounded-md
                                "
                            />
                        </div>
                    </div>
                    <div class="sm:col-span-2">
                        <div class="flex justify-between">
                            <label
                                for="how_can_we_help"
                                class="block text-sm font-medium text-gray-700"
                                >Message</label
                            >
                            <span
                                id="how_can_we_help_description"
                                class="text-sm text-gray-500"
                                >Maximum 500 mots</span
                            >
                        </div>
                        <div class="mt-1">
                            <textarea
                                v-model="formData.body"
                                id="how_can_we_help"
                                name="how_can_we_help"
                                aria-describedby="how_can_we_help_description"
                                rows="4"
                                maxlength="500"
                                class="
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    focus:ring-green-500 focus:border-green-500
                                    border-gray-300
                                    rounded-md
                                "
                                required
                            ></textarea>
                        </div>
                    </div>

                    <div class="relative text-right sm:col-span-2">
                        <button
                            @click="submit()"
                            type="button"
                            :class="[
                                `inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
                                        focus:outline-none focus:ring-2 focus:ring-offset-2`,
                                isValid
                                    ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                                    : 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500',
                            ]"
                        >
                            Soumettre
                        </button>
                        <div
                            v-if="success"
                            class="
                                absolute
                                right-0
                                rounded-md
                                bg-green-50
                                p-4
                                mt-3
                            "
                        >
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg
                                        class="h-5 w-5 text-green-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div class="ml-3">
                                    <h3
                                        class="
                                            text-sm
                                            font-medium
                                            text-green-800
                                        "
                                    >
                                        Message envoyé
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */

    import { addDoc, collection } from 'firebase/firestore';
    export default {
        data: () => ({
            success: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                body: '',
            },
        }),
        computed: {
            isValid() {
                return (
                    !!this.formData.firstName &&
                    !!this.formData.lastName &&
                    !!this.formData.email &&
                    !!this.formData.body &&
                    this.formData.body.length <= 500
                );
            },
        },
        methods: {
            async submit() {
                if (!this.isValid) return;

                const mailCollection = collection(this.$db, 'mail');

                await addDoc(mailCollection, {
                    to: ['annickrheaume7@gmail.com'],
                    message: {
                        subject: 'Message de Frenchiez',
                        text: this.formData.body,
                        html: `<div>
                            <p>
                                Name: ${this.formData.firstName} ${this.formData.lastName}
                            </p>
                            <p>
                                Email: ${this.formData.email}
                            </p>
                            <p>
                                Phone: ${this.formData.phoneNumber}
                            </p>
                            <p>
                                Message: ${this.formData.body}
                            </p>
                        </div>`,
                    },
                });

                this.formData = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    body: '',
                    company: '',
                };

                this.success = true;

                setTimeout(() => {
                    this.success = false;
                }, 2500);
            },
        },
    };
</script>
