<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="bg">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                    <h2 class="text-3xl font-extrabold text-gray-900">
                        Pour nous joindre
                    </h2>
                    <p class="mt-6 text-lg text-gray-500 max-w-3xl">
                        Pour préserver un endroit calme et sécuritaire pour nos
                        petits amours, il est important de
                        <strong>prendre rendez-vous</strong> avant de vous
                        présenter.
                    </p>
                    <div
                        class="
                            mt-10
                            grid grid-cols-1
                            gap-10
                            sm:grid-cols-2
                            lg:grid-cols-4
                        "
                    >
                        <div>
                            <h3 class="text-lg font-medium text-gray-900">
                                Téléphone
                            </h3>
                            <p class="mt-2 text-base text-gray-500">
                                <span class="block"
                                    ><a href="tel:5819990877"
                                        >(581) 999-0877</a
                                    ></span
                                >
                            </p>
                        </div>

                        <div>
                            <h3 class="text-lg font-medium text-gray-900">
                                Réseaux sociaux
                            </h3>
                            <p class="mt-2 text-base text-gray-500">
                                <span class="block"
                                    ><a
                                        href="https://www.facebook.com/94-FrenchieZ-102398994906822"
                                        target="_blank"
                                        >Facebook</a
                                    ></span
                                >
                            </p>
                        </div>
                    </div>
                    <div class="mt-8">
                        <figure>
                            <iframe
                                class="rounded shadow-sm"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2727.8141474041277!2d-71.2936270837706!3d46.86702974676405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb8bd4b0e03dc39%3A0x799fce0675ab2773!2sMarch%C3%A9%20Jean%20Talon!5e0!3m2!1sen!2sca!4v1634407348113!5m2!1sen!2sca"
                                width="100%"
                                height="450"
                                style="border: 0"
                                allowfullscreen=""
                                loading="lazy"
                            ></iframe>
                            <figcaption class="text-sm">
                                Tout près du marché Jean Talon
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <contact-form-vue />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import ScrollBehavior from '@/behaviors/Scroll';
    import ContactFormVue from '@/components/ContactForm.vue';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Contact',
        components: {
            ContactFormVue,
        },
        mounted() {
            ScrollBehavior.toTop();
        },
    });
</script>

<style lang="scss" scoped>
    .bg {
        background-image: url('../assets/pattern.svg');
        background-repeat: repeat;
    }
</style>
