
    import ScrollBehavior from '@/behaviors/Scroll';
    import ContactFormVue from '@/components/ContactForm.vue';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Contact',
        components: {
            ContactFormVue,
        },
        mounted() {
            ScrollBehavior.toTop();
        },
    });
